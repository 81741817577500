'use strict';

var browserLocale = ((navigator.languages && navigator.languages[0]) || navigator.browserLanguage || navigator.language).toLowerCase();
if (window.localStorage.getItem('browserLocale') !== browserLocale) {
    window.localStorage.setItem('browserLocale', browserLocale);
}

var locale = browserLocale;
var supportedLocales = {
    'zu' : true,
    'en-gb': true,
    'en-us': true,
    'de-de': true,
    'fr-fr': true,
    'fr-ca': true,
    'es-es': true,
    'sv-se': true,
    'pt-pt': true/*,
    'no-no': true*/
};

var defaultLanguageLocales = {
	'de': 'de-de',
	'en': 'en-gb',
	'fr': 'fr-fr',
	'sv': 'sv-se'/*,
	'no': 'no-no'*/
}

if (!supportedLocales[locale]) {
	locale = defaultLanguageLocales[locale.substring(0, 2)] || 'en-gb';
    console.log('browser locale [' + browserLocale + '] not supported - default to: ' + locale);
    window.localStorage.setItem('browserLocale', locale);
}

var authenticated = window.localStorage.getItem('synergyportal.authenticated') || window.localStorage.getItem('rcom.app.token')
if (!authenticated || !window.localStorage.getItem('sessionLocale')) {
    // Use browser locale setting until the user is logged in and we can check user preferences
    // No token => Page load while user not yet logged in
    // No sessionLocale => first access using new locale decision logic (ie. sessionLocale doesn't exist even though user token exists)
    window.localStorage.setItem('sessionLocale', locale);
} else {
    // Page load during user logged in session => use current session locale
    // e.g. manual/implicit change of locale causes reload of app
    locale = window.localStorage.getItem('sessionLocale');
}

// defensive code to prevent rogue console.log statements breaking IE9
window.console = window.console || {
    log:function(){},
    warn:function(){},
    error:function(){},
    info:function(){},
    debug:function(){}
};

// versions of
var Versions = {
    Angular: '1.5.8',
    BootStrap: '3.3.6',
    JQuery: '2.1.3',
    JQueryUI: '1.11.4'
};

var AngularCDN = '//ajax.googleapis.com/ajax/libs/angularjs/' + Versions.Angular + '/';

requirejs.config({
    paths: {
        loadCSS: '../lib/loadcss/loadCSS',

        jquery: '//ajax.googleapis.com/ajax/libs/jquery/' + Versions.JQuery + '/jquery.min',
        jqueryui: '//ajax.googleapis.com/ajax/libs/jqueryui/' + Versions.JQueryUI + '/jquery-ui.min',
        jqueryui_i18n: '//ajax.googleapis.com/ajax/libs/jqueryui/' + Versions.JQueryUI + '/i18n/jquery-ui-i18n.min',

        bootstrap: '//maxcdn.bootstrapcdn.com/bootstrap/' + Versions.BootStrap + '/js/bootstrap.min',

        angular: AngularCDN + 'angular.min',
        angularRoute: AngularCDN + 'angular-route.min',
        angularResource: AngularCDN + 'angular-resource.min',
        angularMessages: AngularCDN + 'angular-messages.min',
        angularSanitize: AngularCDN + 'angular-sanitize.min',
        angularTouch: AngularCDN + 'angular-touch.min',

        es6Shim: '//cdnjs.cloudflare.com/ajax/libs/es6-shim/0.34.4/es6-shim.min',

        angularBootstrap: '../lib/angular-bootstrap/ui-bootstrap-tpls.min',
        angularDropdowns: '../lib/angular-dropdowns/angular-dropdowns.min',

        ngTags: '../lib/ng-tags-input/ng-tags-input.min',
        ngInfiniteScroll: '../lib/ng-infinite-scroll',
        pageslide: '../lib/angular-pageslide-directive/angular-pageslide-directive.min',
        dndPageScroll: '../lib/dndPageScroll/jquery.dnd_page_scroll',

        angularWizard: '//cdnjs.cloudflare.com/ajax/libs/angular-wizard/0.8.0/angular-wizard.min',

        highstock: '//code.highcharts.com/stock/2.1/highstock',
        highchartsMore: '//code.highcharts.com/stock/2.1/highcharts-more',
        highchartsSolidGauge: '//code.highcharts.com/stock/2.1/modules/solid-gauge',
        highchartsExporting: '//code.highcharts.com/stock/2.1/modules/exporting',

        i18n: '//cdnjs.cloudflare.com/ajax/libs/require-i18n/2.0.4/i18n.min',
        text: '//cdnjs.cloudflare.com/ajax/libs/require-text/2.0.12/text.min',
        handlebars:  '//cdnjs.cloudflare.com/ajax/libs/handlebars.js/3.0.2/handlebars.min',
        moment: '//cdnjs.cloudflare.com/ajax/libs/moment.js/2.17.1/moment.min',
        momentTimezone: '//cdnjs.cloudflare.com/ajax/libs/moment-timezone/0.5.11/moment-timezone-with-data.min',

        underscore: '//cdnjs.cloudflare.com/ajax/libs/underscore.js/1.8.3/underscore-min',
        sprintf: '//cdnjs.cloudflare.com/ajax/libs/sprintf/1.0.1/sprintf.min',

        uuid: '../lib/uuid',
        hash: '../lib/hash',

        markerClusterer: '../lib/markerclustererplus/markerclusterer.min',
        wicket: '../lib/wicket/wicket',
        wicketGoogleMaps: '../lib/wicket/wicket-gmap3',

        selectize: '//cdnjs.cloudflare.com/ajax/libs/selectize.js/0.12.4/js/standalone/selectize.min',
        angularSelectize: '//cdnjs.cloudflare.com/ajax/libs/machineboy2045-angular-selectize2/3.0.1/angular-selectize',

        dndLists: '../lib/angular-drag-and-drop-lists/angular-drag-and-drop-lists.min',
        MeasureTool: '../lib/measure-tool/MeasureTool.min',
        decimal: '../lib/decimal/decimal.min',
        ngFileSaver: '../lib/fileSaver/angular-file-saver.bundle'
    },
    shim: {
        bootstrap: { deps: [ 'jquery' ] },
        angular: { deps: [ 'jquery' ], exports: 'angular' },
        angularRoute: { deps: [ 'angular' ] },
        angularResource: { deps: [ 'angular' ] },
        angularMessages: { deps: [ 'angular' ] },
        angularSanitize: { deps: [ 'angular' ] },
        angularTouch: { deps: [ 'angular' ] },

        angularBootstrap: { deps: [ 'angular' ] },
        angularDropdowns: { deps : ['angular']},

        ngTags: { deps : ['angular']},
        ngInfiniteScroll: { deps: [ 'jquery', 'angular' ] },
        pageslide: { deps: [ 'angular' ] },
        dndPageScroll: { deps: [ 'jquery' ] },

        jqueryui: { deps: [ 'jquery' ] },
        jqueryui_i18n: { deps: [ 'jqueryui' ] },

        angularWizard: { deps: ['angular']},

        highstock: {  deps: [ 'jquery' ], exports: 'highstock' },
        highchartsMore: { deps: [ 'highstock' ] },
        highchartsSolidGauge: { deps: [ 'highchartsMore' ] },
        highchartsExporting: { deps: [ 'highstock' ] },

        wicket: { exports: 'Wkt' },
        wicketGoogleMaps: { deps: [ 'wicket' ] },

        angularSelectize: { deps: ['angular']},

        dndLists: { deps: [ 'angular' ] },
        ngFileSaver: { deps: [ 'angular' ] }

    },
    // used by requirejs i18n plugin to load localised file
    locale: locale
});

function loadApp() {
require([
    'services/profileReload',
    'angular',
    'synergyPortal/synergyPortal',
    'loadCSS',
    'bootstrap',
    'jqueryui_i18n',
    'app',
    'es6Shim',
    'dndPageScroll',
    'dndLists'
], function (profileReload, angular, synergyPortal) {

    console.log('*** MAIN.JS ***');

    if(window.opener != null){
       var windowOriginOpener = window.opener.origin;
       // Check if the pattern matches synergy part of the URL
       if (/[synergy]*[fleetmanager]/g.test(windowOriginOpener)) {
           var localStorageData = window.opener.localStorage;
           // Update new window's localStorage with data from the parent window
           for (var key in localStorageData) {
               localStorage.setItem(key, localStorageData[key]);
           }
       }
    }

    loadCSS('//fonts.googleapis.com/css?family=Roboto:300');
    loadCSS('//fonts.googleapis.com/css?family=Open+Sans:400,600');
    loadCSS('//maxcdn.bootstrapcdn.com/bootstrap/' + Versions.BootStrap + '/css/bootstrap.min.css');
    loadCSS('//maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
    loadCSS('//ajax.googleapis.com/ajax/libs/jqueryui/' + Versions.JQueryUI + '/themes/smoothness/jquery-ui.min.css');

    loadCSS('css/rcom-core.css');
    loadCSS('css/rcom-skin.css');
    loadCSS('css/rcom-cat.css');
    loadCSS('css/angular-dropdowns.min.css');
    loadCSS('css/ng-tags-input.min.css');
    loadCSS('css/ng-tags-input.min.css');
    loadCSS('//cdnjs.cloudflare.com/ajax/libs/selectize.js/0.12.4/css/selectize.default.css');
    loadCSS('css/orb-styles.css'); // Branding changes FME-5557 & FME-5558

    var themeName = synergyPortal.getSynergyTheme();
    //Loads theme based css if the user is in synergy, else loads orbTheme always
    //Get the theme based css from commonAssets
    loadCSS('css/'+themeName+'.css');

    // Set the locale for the jqueryui datepicker
    var code = locale.substr(0, 2);
    var jqueryui_locale = code + '-' + locale.substr(3, 2).toUpperCase();
    if ($.datepicker.regional[jqueryui_locale]) {
        $.datepicker.setDefaults($.datepicker.regional[jqueryui_locale]);
    } else {
        if ($.datepicker.regional[code]) {
            $.datepicker.setDefaults($.datepicker.regional[code]);
        }
    }

        profileReload.always(function() {
            // We need to load the angular-locale support before we start the app (can't use Google CDN for the locale)
            var localeFile = '//cdnjs.cloudflare.com/ajax/libs/angular-i18n/' + Versions.Angular + '/angular-locale_' + locale + '.js';
            require([localeFile],
                function() {
                    angular.bootstrap(document, ['rcom.app']);
                },
                function() {
                    angular.bootstrap(document, ['rcom.app']);
                });

            $().dndPageScroll();
        });
    });
}


require(['mantleConfig'], function(mantleConfig) {
    mantleConfig.promise.done(loadApp);
});
